import { makeStyles, Modal, Theme, Button, Grid } from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
	confirmationModalService,
	IConfirmationState,
} from "src/services/confirmation-modal.service";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = (bodyContentIsString: boolean, scrollable: boolean) =>
	makeStyles((theme: Theme) => ({
		root: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			border: "none",
		},
		modal: {
			display: "flex",
			padding: theme.spacing(1),
			alignItems: "center",
			justifyContent: "center",
			border: "none",
		},
		modalContainer: {
			minWidth: 500,
			maxHeight: "100%",
			backgroundColor: theme.palette.background.paper,
			fontFamily: theme.typography.fontFamily,
			color: theme.palette.text.primary,
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-start",
			flexDirection: "column",
			overflowY: scrollable ? "auto" : "inherit",
		},
		closeButton: {
			width: "100%",
			display: "flex",
			justifyContent: "flex-end",
			paddingTop: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		contentContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			height: "100%",
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
			paddingBottom: theme.spacing(3),
		},
		headerContainer: {
			fontSize: 20,
			fontWeight: "bold",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			width: "100%",
			display: "grid",
			gridTemplateColumns: "1fr auto",
			gridTemplateRows: "1fr auto",
		},
		headerText: {
			justifySelf: "center",
			gridRow: 2,
		},
		icon: {
			justifySelf: "end",
		},
		body: {
			width: "100%",
			padding: theme.spacing(1),
			textAlign: bodyContentIsString ? "center" : "left",
		},
		footer: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
			position: "relative",
			paddingTop: theme.spacing(3),
		},
		modalWidthMedium: {
			maxWidth: 950,
		},
		modalWidthLarge: {
			maxWidth: 1200,
		},
	}));

const ConfirmationModal = () => {
	// Use confirmation modal state
	const [
		{
			visible = false,
			message,
			header = "Are you sure",
			confirmText = "Confirm",
			hideClose = true,
			cancelText = "Cancel",
			hideButtons = false,
			hideCancel = false,
			scrollable = false,
			disableEnforceFocus = false,
			disableCloseOnBackdropClick = false,
			modalStretch = "medium",
		},
		setConfirmationmodalState,
	] = useState<IConfirmationState>({} as IConfirmationState);
	// Check if message is a string
	const bodyContentIsString = typeof message === "string";
	// Set classes
	const classes = useStyles(bodyContentIsString, scrollable)();

	const handleClose = useCallback(
		(_event: {}, reason: "backdropClick" | "escapeKeyDown") => {
			if (disableCloseOnBackdropClick && reason === "backdropClick") {
				return;
			}
			confirmationModalService.reject();
		},
		[disableCloseOnBackdropClick],
	);

	const modalContainerWidthClass =
		modalStretch === "large"
			? classes.modalWidthLarge
			: classes.modalWidthMedium;

	const modalContainerClass = [
		classes.modalContainer,
		modalContainerWidthClass,
	].join(" ");

	useEffect(() => {
		// Get modal state stream
		const confirmationModalState$ = confirmationModalService.getState();
		// Subscribe to stream
		const subscription = confirmationModalState$.subscribe((state) => {
			setConfirmationmodalState(state);
		});
		// Unsubscribe on destroy
		return () => subscription.unsubscribe();
	}, []);

	return (
		<Grid container className={classes.root}>
			<Modal
				className={classes.modal}
				open={visible}
				onClose={handleClose}
				disableEnforceFocus={disableEnforceFocus}
			>
				<Grid className={modalContainerClass}>
					<div className={classes.closeButton}>
						{!hideClose && (
							<CloseIcon
								className={classes.icon}
								style={{ cursor: "pointer" }}
								onClick={() => confirmationModalService.reject()}
							/>
						)}
					</div>
					<div className={classes.contentContainer}>
						<div className={classes.headerContainer}>
							<div className={classes.headerText}>{header}</div>
						</div>
						<div className={classes.body}>{message}</div>
						{!hideButtons && (
							<div className={classes.footer}>
								{!hideCancel && (
									<Button
										variant="contained"
										color="secondary"
										type="button"
										onClick={() => confirmationModalService.reject()}
									>
										{cancelText}
									</Button>
								)}
								<Button
									style={{ marginLeft: 16 }}
									variant="contained"
									color="primary"
									type="button"
									onClick={() => confirmationModalService.accept()}
								>
									{confirmText}
								</Button>
							</div>
						)}
					</div>
				</Grid>
			</Modal>
		</Grid>
	);
};

export default ConfirmationModal;
